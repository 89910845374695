<template>
	<div class="booking-vise">
		<div class="booking-visea">
			<div class="">
				<div class="booking-viseb">
					<!-- 常旅客信息1 -->
					<div class="">
						一起飞国际机票网>签证>墨西哥旅游签证>资料填写
					</div>
					<div class="booking-vised">
						<!-- 常旅客信息2 -->
						<div class="booking-viseda">1</div>
						<div class="booking-visedb">常旅客信息</div>
						<!-- 常旅客信息3 -->
					</div>

					<div class="booking-visee">
						<!-- 申请人信息1 -->
						<div class="booking-viseea">
							<!-- 申请人信息 -->
							<div class="booking-viseeb"></div>
							<div class="booking-viseec">申请人信息</div>
							<div class="booking-viseed">填写说明</div>
						</div>
						<div class="booking-viseee">
							<!-- 复选框 -->
							<el-checkbox-group v-model="checkList">
								<!-- 复选框1 -->
								<el-checkbox class="booking-viseeea" label="老大"></el-checkbox>
								<el-checkbox class="booking-viseeea" label="肖二"></el-checkbox>
								<el-checkbox class="booking-viseeea" label="张三"></el-checkbox>
								<el-checkbox class="booking-viseeea" label="李四"></el-checkbox>
								<el-checkbox class="booking-viseeea" label="王五"></el-checkbox>
								<el-checkbox class="booking-viseeea" label="赵六"></el-checkbox>
								<el-checkbox class="booking-viseeea" label="王七"></el-checkbox>
								<el-checkbox class="booking-viseeea" label="王八"></el-checkbox>
								<el-checkbox class="booking-viseeea" label="老九"></el-checkbox>
							</el-checkbox-group>
						</div>
						<div class="booking-applicat">
							<!-- 第1位申请人 -->
							<div class="booking-applicata">第1位申请人 </div>
							<div class="booking-viseef"></div>
						</div>
					</div>

					<div class="booking-namezzz">
						<div class="booking-namezz">
							<div class="booking-namez">
								<!-- 申请人以内 -->
								<div class="booking-name">
									<!-- 姓名 -->
									<div class="booking-namec">
										<div class="booking-namea">姓名:</div>
										<div class="booking-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="请输入内容" v-model="input" clearable>
										</el-input>
									</div>
								</div>

								<div class="booking-name">
									<!-- 证件类型 -->
									<div class="booking-namec">
										<div class="booking-namea">证件类型:</div>
										<div class="booking-nameb">*</div>
									</div>
									<div class="">
										<el-select v-model="value" placeholder="请选择">
											<el-option v-for="item in options" :key="item.value" :label="item.label"
												:value="item.value">
											</el-option>
										</el-select>
									</div>
								</div>
								<div class="booking-name">
									<!-- 证件号码 -->
									<div class="booking-namec">
										<div class="booking-namea">证件号码:</div>
										<div class="booking-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="请输入内容" v-model="input" clearable>
										</el-input>
									</div>
								</div>
								<div class="booking-name">
									<!-- 证件有效期 -->
									<div class="booking-namec">
										<div class="booking-namea">证件有效期:</div>
										<div class="booking-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="请输入内容" v-model="input" clearable>
										</el-input>
									</div>
								</div>
								<div class="booking-name">
									<!-- 出生日期 -->
									<div class="booking-namec">
										<div class="booking-namea">出生日期:</div>
										<div class="booking-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="请输入内容" v-model="input" clearable>
										</el-input>
									</div>
								</div>

								<div class="booking-name booking-namer">
									<!-- 性别 -->
									<div class="booking-namec">
										<div class="booking-namea">性别:</div>
										<div class="booking-nameb">*</div>
									</div>
									<div class="">
										<el-radio v-model="radio" label="1">男性</el-radio>
										<el-radio v-model="radio" label="2">女性</el-radio>
									</div>
								</div>

								<div class="booking-name">
									<!-- 身份类型 -->
									<div class="booking-namec">
										<div class="booking-namea">身份类型:</div>
										<div class="booking-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="不限" v-model="input" clearable>
										</el-input>
									</div>
								</div>

								<div class="booking-names">
									<!-- 身份类型 -->
									<div class="">
										<el-checkbox v-model="checked">保存</el-checkbox>
									</div>
									<div class="booking-namesq">清空</div>
								</div>
							</div>
						</div>
						<div class="booking-increase">
							<div class="booking-increasea">
								<div class="booking-increaseaa iconfont icon-jiahao"></div>
								<div class="booking-increaseab">再增加一位</div>
							</div>
						</div>
					</div>
				</div>

				<div class="booking-journey">
					<!-- 行程 -->
					<div class="booking-vised booking-journeya">
						<!-- 行程日期2 -->
						<div class="booking-viseda">2</div>
						<div class="booking-visedb">行程日期</div>
						<!-- 行程日期3 -->
					</div>
					<div class="">
						<el-input placeholder="不限" v-model="input" clearable>
						</el-input>
					</div>
				</div>

				<div class="booking-journeyc">
					<div class="booking-journeyb">
						<!-- 行程 -->
						<div class="booking-vised booking-journeya">
							<!-- 联系人信息2 -->
							<div class="booking-viseda">3</div>
							<div class="booking-visedb booking-relation">联系人信息</div>
							<!-- 联系人信息3 -->
							<div class="booking-relationa">
								(请准确填写联系信息，重要信息我们会通过短信，邮箱等方式通知您)
							</div>
						</div>
					</div>
					<div class="booking-relationd">
						<div class="booking-relationb">
							<!-- 联系人 -->
							<div class="booking-relationc">
								<div class="booking-namea">联系人:</div>
							</div>
							<div class="">
								<el-input placeholder="请输入联系人名字" v-model="input" clearable>
								</el-input>
							</div>
						</div>
						<div class="booking-relationb">
							<!-- 联系人 -->
							<div class="booking-relationc">
								<div class="booking-namea">联系人手机:</div>
							</div>
							<div class="">
								<el-input placeholder="请输入联系人手机" v-model="input" clearable>
								</el-input>
							</div>
						</div>
						<div class="booking-relationb">
							<!-- 联系人 -->
							<div class="booking-relationc">
								<div class="booking-namea">联系人邮箱:</div>
							</div>
							<div class="">
								<el-input placeholder="请输入联系人邮箱" v-model="input" clearable>
								</el-input>
							</div>
						</div>
						<div class="booking-relatione">
							<!-- 联系人 -->
							<div class="booking-relationc">
								<div class="booking-namea">备注:</div>
							</div>
							<div class="">
								<el-input class="booking-input" type="textarea" :rows="2" placeholder="填写备注"
									resize="none" v-model="textarea">
								</el-input>
							</div>
						</div>
					</div>
				</div>
				<div class="booking-journeyc">
					<div class="booking-journeyb">
						<!-- 行程 -->
						<div class="booking-vised booking-journeya">
							<!-- 联系人信息2 -->
							<div class="booking-viseda">4</div>
							<div class="booking-visedb booking-relation">发票配送信息</div>
							<!-- 联系人信息3 -->
						</div>
					</div>
					<div class="booking-dispatching">
						<!-- 配送 -->
						<el-radio class="booking-dispatchinga" v-model="radio1" label="1">无需配送，直接把电子行程单发到我邮箱</el-radio>
						<el-radio class="booking-dispatchinga" v-model="radio1" label="2">快递</el-radio>
						<el-radio v-model="radio1" label="3">自取（取票地址：广州市白云区黄石懂路黄园一街2号6楼）</el-radio>
						<div class="booking-dispatchingb"></div>
						<div class="booking-relationa">
							<div class="">商家现金卷</div>
						</div>
						<div class="">
							<!-- 商家现金卷选中打勾 -->
							<div class="booking-merchantt">
								<div class="booking-merchant">
									<div class="booking-merchantb" @click="show=!show">
										<div class="booking-merchanta">
											<div class="iconfont icon-querenrenwuyiwancheng book-tick" v-show="show==1">
											</div>
											<div class="iconfont icon-chalv-duoxuankuang book-ticka" v-show="show==0">
											</div>
										</div>
										<div class="booking-activate">
											<!-- 发票配送信息 -->
											<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index7.png"
												class="booking-activatea">
											<div class="booking-universal">
												<div class="booking-universala">
													<div class="">¥</div>
													<div class="booking-universalb">200</div>
												</div>
												<div class="booking-universalc">所有订单通用</div>
												<div class="booking-universald">有效期2018.03.22-2018.03.28</div>
											</div>
											<div class="booking-universale">已激活</div>
										</div>
									</div>
									<div class="booking-merchantc">
										<div class="">-¥100</div>
									</div>
								</div>
								<div class="booking-merchantb" @click="show1=!show1">
									<div class="booking-merchanta">
										<div class="iconfont icon-querenrenwuyiwancheng book-tick" v-show="show1==1">
										</div>
										<div class="iconfont icon-chalv-duoxuankuang book-ticka" v-show="show1==0">
										</div>
									</div>
									<div class="booking-activate">
										<!-- 发票配送信息 -->
										<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index7.png"
											class="booking-activatea">
										<div class="booking-universal">
											<div class="booking-universala">
												<div class="">¥</div>
												<div class="booking-universalb">200</div>
											</div>
											<div class="booking-universalc">所有订单通用</div>
											<div class="booking-universald">有效期2018.03.22-2018.03.28</div>
										</div>
										<div class="booking-universale">已激活</div>
									</div>
								</div>
								<div class="booking-money">
									<div class="booking-moneya">
										<div class="">支付总金额：</div>
										<div class="booking-moneyb">¥1900</div>
									</div>
								</div>
							</div>
							<div class="booking-submit">
								<div class="booking-submita">提交订单</div>
							</div>
						</div>
					</div>

				</div>
			</div>


			<div class="booking-visecc">
				<div class="booking-visec" v-if="VisaDetailInfo!=null">
					<!-- 已选产品 -->
					<div class="booking-product">已选产品</div>
					<div class="booking-producta">
						<img :src="VisaDetailInfo.VisaTypeImg" class="booking-productb">
					</div>
					<div class="booking-productc">
						<div class="booking-productd">受理时间：{{VisaDetailInfo.Needday}}</div>
						<div class="booking-producte">签证有效期：{{VisaDetailInfo.Validday}}</div>
						<div class="booking-producte">可停留天数：{{VisaDetailInfo.Stay}}</div>
						<div class="booking-producte">签证类型：{{VisaDetailInfo.VisaTypeName}}</div>
						<!-- <div class="booking-productf">
							<div class="">2018-11-07 至 2018-11-10</div>
							<div class="">共4天</div>
						</div> -->
					</div>
				</div>
				<div class="booking-deal">
					<!-- 应付总额 -->
					<div class="booking-deala">
						<div class="booking-dealab">应付总额</div>
						<div class="booking-dealac">¥1900</div>
					</div>
					<div class="booking-dealb">
						<div class="booking-deale">亚洲计划成人款x2</div>
						<div class="booking-dealc">
							<div class="booking-deald"></div>
						</div>
						<div class="booking-dealac">¥800 x2</div>
					</div>
					<div class="booking-dealb">
						<div class="booking-deale">亚洲计划儿童款x2</div>
						<div class="booking-dealc">
							<div class="booking-deald"></div>
						</div>
						<div class="booking-dealac">¥800 x2</div>
					</div>
					<div class="booking-dealb">
						<div class="booking-deale">现金卷</div>
						<div class="booking-dealc">
							<div class="booking-deald"></div>
						</div>
						<div class="booking-dealac">-¥800</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var localdatas = require('@/utils/localdatas.js');
	var webconfig = require('@/utils/webconfig.js');
	var staticData = require('@/data/staticData.js');

	var visaApi = require('@/api/visaApi.js');
	var that;
	import {
		submitOrder
	} from '@/api/order';

	export default {
		data() {
			return {
				title: '签证确认订单页',
				show: 0,
				show1: 0,
				checkList: [],
				input: '',
				options: [{
					value: '选项1',
					label: '户口本'
				}, {
					value: '选项2',
					label: '外籍'
				}, {
					value: '选项3',
					label: '身份证'
				}, {
					value: '选项4',
					label: '驾驶证'
				}],
				value: '',
				radio: '1',
				radio1: '1',
				textarea: '',
				checked: '',

				TravelDate: '',
				VisaDetailInfo: null,
				scrollheight: 600,
				form: {
					linkman: '',
					area: '0086',
					phone: '',
					email: '',
					remark: ''
				},
				accountinfo: '',
				IsChinaCity: false,
				PassengerListData: [],
				TotalAmount: 0,
				PassengerIdentitys: staticData.PassengerIdentitys,
				selectCoupon: null
			};
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			// if (!yqfCommon.isNullOrEmpty(options.TravelDate)) {
			// 	that.TravelDate = options.TravelDate;
			// }
			that.VisaDetailInfo = localdatas.getvisaBookInfo();
			console.log(that.VisaDetailInfo);
		},
		methods: {

		}
	};
</script>

<style lang="scss">
	@import '../../style/visa/booking.scss';
</style>
